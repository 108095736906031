a {
  color:cornflowerblue;
}

p {
  font-family: 'Overpass', sans-serif;
}

.App {
  text-align: center;
}

.BirdPicture {
  height: 150px;
}

.Link {
  font-family: 'Overpass' sans-serif ;
  font-size: larger;
  margin-top: 20px;
}

.Loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid cornflowerblue;
  border-bottom: 16px solid cornflowerblue;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  display: inline-flex;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.HeaderButton {
  height: 100%;
}

.HeaderButtonDiv {
  width: 35%;
}

.HeaderTopic {
  width: 30%;
}

.FrontPagePicture {
  height: 600px;
  margin-left: 0%;
  margin-right: 0%;
  margin-bottom: 20px;
}

.FormElement {
  margin-bottom: 20px;
  margin-left: 20%;
  margin-right: 20%;
  width: 60%;
}

.FormElementLabel {
  float: left;
  margin-bottom: 20px;
}

.FormInputElementLabel {
  float: 'left';
  margin-right: '40%';
}

.ErrorBox {
  border-style:solid;
  border-radius: 25px;
  border-color: orangered;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
  .HeaderButton {
    height: 50%;
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .FrontPagePicture {
    height: 200px;
  }

  .HeaderTopic {
    width: 40%;
  }

  .HeaderButtonDiv {
    width: 30%;
  }

  .FormInputElementLabel {
    margin-right: '0%';
  }  
}